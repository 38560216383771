import React, { useEffect, useState } from "react";
import "../../static/common.css";
import PlanItem from "./PlanItem";
import DeleteIcon from '../../static/icons/Delete.svg';
import TableRows from "./TableRows";
import CostIcon from "../../static/icons/Euro.png";
import ContractIcon from "../../static/icons/Contract.png";
import { t } from "../../translations/Translation";
import { GetWeeklyPlansEmployees } from "../../routes/ApiEndPoints";
import { APICALL as AXIOS } from "../../services/AxiosServices";



export default function PlanRow({planData, dates_array, dataRefresh, employee_types, showAvailability, workstation, createPlan, displayshift, deleteTableRows, rowsData, appliedFilterStatus,
                                shiftStatus, names, popupState, refreshStatus, savedWorkstations, searchedEmployees, availableDates, unAvailableDates, remarkDates, addLeaveStatus,
                                setAddLeaveStatus, EditLeave, enableShifts, deletePublicHoliday}){
    const regex = /(<([^>]+)>)/ig;
    const [employeeData, setEmployeeData] = useState([]);

    useEffect(()=>{

        let requestData = {
            'year'       : localStorage.getItem('year'),
            'week_number': localStorage.getItem('week_number'),
            'lid'        : JSON.parse(localStorage.getItem('location')).value,
            "wid"        : workstation,
            "employee_types": JSON.stringify(employee_types),
            "token"      : localStorage.getItem('token'),
        }
        if (!popupState){
        AXIOS.service(GetWeeklyPlansEmployees, 'POST', requestData)
        .then(result => {
            if (result && result.status === 200){
                setEmployeeData(result.data);
            } else {
                setEmployeeData([]);
            }
        })
        .catch(error => {
            // console.log(error);
        })}
    }, [dataRefresh, showAvailability, enableShifts, refreshStatus, popupState, appliedFilterStatus, localStorage.getItem('week_number'), localStorage.getItem('year'),localStorage.getItem('location'), addLeaveStatus])

    return(
        <section>
        {Object.keys(employeeData).map((value, index)=>{
            return (
                <tr key={value} className="main-width row m-0">
                    <td className="employee-width text-center border-right text-vertical-center" title={employeeData[index]['employee_name']}>
                        {/* <small className="mb-0">{planData[index]['employee_name'].length > 12 ? planData[index]['employee_name'].replace(regex, '').substring(0,10)+'...':planData[value]['employee_name']}</small><br></br> */}
                        <small className="mb-0">{employeeData[index]['employee_name']}</small><br></br>
                        {employeeData[index]['employee_types'] !== undefined && employeeData[index]['employee_types'].map((val, index) => {
                            return(<img
                                key={val}
                                className={"edit-leave-icon pointer mr-1"}
                                src={val.icon}
                                alt="employee-type"
                                title={val.employee_type}
                            ></img>)
                        })}
                    </td>
                    {employeeData[index] !== undefined &&
                        <PlanItem
                            planData = {employeeData[value]['plans']}
                            employee_id={employeeData[index]['employee_id']}
                            dates={dates_array}
                            showAvailability={showAvailability}
                            createPlan={createPlan}
                            workstation={workstation}
                            setAddLeaveStatus={setAddLeaveStatus}
                            EditLeave={EditLeave}
                            enableShifts={enableShifts}
                            deletePublicHoliday={deletePublicHoliday}
                        ></PlanItem>
                    }
                    {employeeData[index] !== undefined &&
                        <td className="px-1 border-right total-width d-flex justify-content-between" >
                            {employeeData[index]['employee_cost'] !== null && employeeData[index]['employee_cost'] !== "" && employeeData[index]['employee_cost'] !== undefined &&
                            <small title={t("WEEKLY_TOTAL_COST")} className="plan-item-font">
                                <img className="planitem-icons-2" src={CostIcon}></img>{employeeData[index]['employee_cost']}
                            </small>}
                            <small title={t("WEEKLY_TOTAL_CONTRACT_HOURS")} className="plan-item-font" id={employeeData[index]['employee_contract_completed'] ? '' : 'contract-red'}>
                                <img className="planitem-icons-2" src={ContractIcon}></img>{employeeData[index]['employee_used_total_contract_hours'] + (employeeData[index]['employee_actuel_total_contract_hours'] !== null ? '/' + employeeData[index]['employee_actuel_total_contract_hours']: '')}
                            </small>
                        </td>
                    }
                    <td className="px-0 text-center delete-width" title={t("DELETE_ROW")}>
                        { (parseInt(localStorage.getItem('year')) > parseInt(new Date().getFullYear()) || (parseInt(localStorage.getItem('year')) == parseInt(new Date().getFullYear()) && parseInt(localStorage.getItem('week_number')) >= parseInt(localStorage.getItem('current_week')))) && <img className="image2-icon pointer" src={DeleteIcon} onClick={(key)=>(deleteTableRows(key, employeeData[index]['employee_id'], 0, 0, workstation))} alt={t('DELETE_ROW')}></img> }
                    </td>
                </tr>
            )
        })}
        {/* {rowsData !== [] && */}
            <TableRows
                rowsData={rowsData}
                dates={dates_array}
                deleteTableRows={deleteTableRows}
                names={names}
                createPlan={createPlan}
                enableShifts={enableShifts}
                workstation={workstation}
                planStatus={"multi"}
                showAvailability={showAvailability}
                popupState = {popupState}
                refreshStatus = {refreshStatus}
                savedWorkstations={savedWorkstations}
                searchedEmployees={searchedEmployees}
                availableDates={availableDates}
                unAvailableDates={unAvailableDates}
                remarkDates={remarkDates}
            />
        {/* } */}
        </section>
    )
}